<template>
  <div class="page">
    <NavBar />
    <div class="contact container">
      <div class="section contact__content">
        <h1 class="contact__title">CONTACT</h1>
        <p class="contact__text">Disponible sur région parisienne et Lyon</p>
        <ul class="contact__list">
          <p class="contact__elt">
            <a href="mailto:contact@charlesveneau.com" class="contact__link">
              <BaseIcon name="mail"></BaseIcon>contact@charles.veneau.com
            </a>
          </p>
          <p class="contact__elt" v-on:click="testClick = !testClick">
            <a href="tel:0770042261" class="contact__link"
              ><BaseIcon name="phone"></BaseIcon> 07.70.04.22.61</a
            >
          </p>
          <p class="contact__elt">
            <a
              href="https://www.linkedin.com/in/charles-veneau-08342895/"
              target="_blank"
              class="contact__link"
            >
              <BaseIcon name="linkedin"></BaseIcon>
              LinkedIn
            </a>
          </p>
          <p class="contact__elt">
            <a
              href="https://github.com/charlesVeneau"
              target="_blank"
              class="contact__link"
            >
              <BaseIcon name="github"></BaseIcon>
              Gitbub
            </a>
          </p>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Contact",
      meta: [
        {
          name: "Contact",
          content: `Disponible sur la région parisienne et Lyon.
      contact@charlesveneau.com`
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped></style>
